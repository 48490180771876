<template>
  <main id="content" role="main" class="overflow-hidden">

    <!-- ========== Hero Section ========== -->
    <div class="position-relative overflow-hidden bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-trust-hero.svg') + ')'}">
      <!-- Breadcrumb -->
      <div class="container space-top-2 mt-md-6 pl-0">
        <div class="row justify-content-sm-between">
          <div class="col-md">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-md-3 mb-0">
                <li class="breadcrumb-item">
                  <!-- <a href="/resource-library"> -->
                  <router-link :to="{ path: '/resource-library' }">
                    Resource Library
                  </router-link>
                  <!-- </a> -->
                </li>
                <li class="breadcrumb-item active" aria-current="page">beNovelty x OneDegree – 透過網絡保險提升網絡安全</li>
              </ol>
            </nav>
          </div>
          <div class="col-md-auto text-md-right mt-2 ml-3 justify-content-end">
            <!-- <a class="btn btn-soft-primary btn-xs" href="/resource-library/benovelty-onedegree-cyber-insurance"> -->
            <router-link class="btn btn-soft-primary btn-xs" :to="{ path: '/resource-library/benovelty-onedegree-cyber-insurance' }">
              Change to English Version <i class="fas fa-exchange-alt" />
            </router-link>
            <!-- </a> -->
          </div>
        </div>
      </div>
      <!-- End Breadcrumb -->
      <div class="container position-relative z-index-2 space-top-2 space-bottom-3">
        <div class="w-100 w-md-80 w-lg-100 mx-auto text-center">
          <div class="mb-4">
            <img class="max-w-23rem" src="@/assets/svg/logos/logo.svg"> <br class="d-sm-none"> <span class="small font-weight-bold mx-2">x</span> <br class="d-sm-none"> <img class="max-w-23rem" src="@/assets/img/partners/onedegree.png">
          </div>
          <h1>網絡保險 - 專為中小企數碼轉型而提供保障</h1>
        </div>
      </div>
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== About Section ========== -->
    <div class="container space-2 space-lg-3">
      <div class="row align-items-lg-center">
        <div class="col-lg-6">
          <div class="mb-5 mb-md-7">
            <span class="d-block small font-weight-bold text-cap mb-2">活動內容</span>
            <h2 class="mb-3">OneDegree x beNovelty - <br class="d-none d-lg-block">透過網絡保險提升網絡安全</h2>
            <div v-for="item in about_list" :key="item" class="media mb-3">
              <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                <i class="fas fa-check" />
              </span>
              <div class="media-body">
                <p>{{ item }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6" data-aos="fade-left">
          <div class="w-90 w-lg-100 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/cyber-insurance/about.svg">
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End About Section ========== -->

    <!-- ========== Features Section ========== -->
    <figure>
      <svg
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        height="64px"
        viewBox="0 0 1921 273"
        style="margin-bottom: -8px; enable-background:new 0 0 1921 273;"
        xml:space="preserve"
      >
        <polygon fill="#f9fbff" points="0,273 1921,273 1921,0 " />
      </svg>
    </figure>
    <div class="bg-light">
      <div class="container space-2">
        <!-- Title -->
        <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
          <span class="d-block small font-weight-bold text-cap mb-2">產品特點</span>
          <h2>輕鬆保障網絡風險</h2>
        </div>
        <!-- End Title -->

        <div class="row">
          <div v-for="item in feature_list" :key="item.image_url" class="col-md-6 col-lg-3 mb-5 mb-lg-0">
            <div class="text-center">
              <figure class="max-w-10rem mx-auto mb-4">
                <img class="img-fluid" :src="require('@/assets/svg/icons/' + item.image_url)">
              </figure>
              <h4 v-html="item.content" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <figure>
      <svg
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        height="64px"
        viewBox="0 0 1921 273"
        style="margin-bottom: -8px; enable-background:new 0 0 1921 273;"
        xml:space="preserve"
      >
        <polygon fill="#f9fbff" points="1921,0 0,0 0,273 " />
      </svg>
    </figure>
    <!-- ========== End Features Section ========== -->

    <!-- ========== FiberAPI Section ========== -->
    <div class="container space-2">
      <div class="row align-items-lg-center">
        <div class="col-lg-6 order-lg-2">
          <div class="mb-5 mb-md-7">
            <span class="d-block small font-weight-bold text-cap mb-2">客戶案例</span>
            <h2 class="mb-3">FiberAPI</h2>
            <div class="media mb-3">
              <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                <i class="fas fa-check" />
              </span>
              <div class="media-body">
                <p>FiberAPI致力以API技術開發各種工具，協助中小企簡化營運流程及管理財務，提高整體效率。</p>
              </div>
            </div>
            <div class="media mb-3">
              <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                <i class="fas fa-check" />
              </span>
              <div class="media-body">
                <p>在處理客戶的財務數據時，FiberAPI採取銀行級別的網絡安全措施。 網絡保險有助於管理網絡風險，並為企業提供額外的保護。</p>
              </div>
            </div>
            <a id="ga-partnership-offer-cyber-insurance-fiber-button" class="btn btn-primary transition-3d-hover" href="https://fiberapi.com/" target="_blank">了解更多關於 FiberAPI</a>
          </div>
        </div>

        <div class="col-lg-6 order-lg-1" data-aos="fade-right">
          <div class="w-90 w-lg-100 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/cyber-insurance/fiberapi.svg">
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End FiberAPI Section ========== -->

    <!-- ========== Register OAH Section ========== -->
    <figure>
      <svg
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        height="64px"
        viewBox="0 0 1921 273"
        style="margin-bottom: -8px; enable-background:new 0 0 1921 273;"
        xml:space="preserve"
      >
        <polygon fill="#f9fbff" points="0,273 1921,273 1921,0 " />
      </svg>
    </figure>
    <div class="bg-light">
      <div class="container space-2">
        <div class="w-lg-85 mx-lg-auto" data-aos="fade-up">
          <div class="card bg-primary text-white overflow-hidden p-4">
            <div class="row justify-content-md-start align-items-md-center text-center text-md-left">
              <div class="col-md-6 offset-md-2 col-xl-8 offset-xl-2 mb-3 mb-md-0">
                <h3 class="text-white px-2 px-sm-0 mb-0">想為您的企業帶來更全面的網絡保障？</h3>
              </div>
              <div class="col-md-4 col-xl-2 text-md-right">
                <a id="ga-partnership-offer-cyber-insurance-learn-more-button" class="btn btn-light transition-3d-hover" href="javascript:;" data-toggle="modal" data-target="#oahForm">了解更多</a>
              </div>
            </div>

            <!-- SVG Component -->
            <figure class="w-15 d-none d-md-block content-centered-y ml-n4">
              <img class="img-fluid" src="@/assets/svg/illustrations/announcement.svg">
            </figure>
            <!-- End SVG Component -->
          </div>
        </div>
      </div>
    </div>
    <figure>
      <svg
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        height="64px"
        viewBox="0 0 1921 273"
        style="margin-bottom: -8px; enable-background:new 0 0 1921 273;"
        xml:space="preserve"
      >
        <polygon fill="#f9fbff" points="1921,0 0,0 0,273 " />
      </svg>
    </figure>
    <!-- ========== End Register OAH Section ========== -->

    <!-- ========== News ========== -->
    <div class="container space-2">
      <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
        <span class="d-block small font-weight-bold text-cap mb-2">最新消息</span>
        <h2>媒體中心</h2>
      </div>

      <!-- Media Coverage Item  -->
      <div class="row card-lg-gutters-3" data-aos="fade-up">
        <div v-for="item in media_coverage_list" :key="item.title" class="col-12 col-md-6 col-lg-4 mb-4">
          <a class="card h-100 transition-3d-hover" :href="item.url" target="_blank" data-gtm-att="ga-partnership-offer-cyber-insurance-card">
            <div class="card-img-top position-relative">
              <img class="card-img-top" :src="require('@/assets/img/resource-library/benovelty_onedegree_fiberapi_20201112/images/' + item.image_url)" alt="Event Title">
              <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                  <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                </svg>
              </figure>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <span class="d-block small font-weight-bold text-cap">{{ item.category }}</span>
              </div>
              <div class="mb-5">
                <h4>{{ item.title }}</h4>
              </div>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">了解更多 <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </a>
        </div>
      </div>
      <!-- End Media Coverage Item  -->
    </div>
    <!-- ========== End News ========== -->

    <!-- ========== Subscription Section ========== -->
    <div class="container-fluid gradient-y-sm-primary mt-5">
      <div class="row justify-content-sm-center align-items-lg-center text-center space-2 space-lg-1">
        <div class="col-lg-3 d-none d-lg-block" />

        <div class="col-sm-8 col-lg-6">
          <div class="mb-5">
            <h2>訂閱電子報</h2>
            <div class="w-lg-90 mx-auto">
              <p>訂閱我們的電子報以收到我們的最新消息、推廣以及活動資訊。</p>
            </div>
          </div>
          <!-- Subscribe Form -->
          <div class="w-md-75 w-lg-80 mx-auto form-subscribe">
            <!-- Begin Mailchimp Signup Form -->
            <div id="mc_embed_signup">
              <form id="mc-embedded-subscribe-form" ref="mcEmbeddedSubscribeForm" action="https://openapihub.us18.list-manage.com/subscribe/post?u=b928e5178a4f8a241b088732f&amp;id=dae542b6e6&amp;f_id=00a108e7f0" method="post" name="mc-embedded-subscribe-form" class="validate" target="blank">
                <div class="mc-field-group">
                  <label for="mce-EMAIL">電子郵件 <span class="asterisk">*</span>                  </label>
                  <input id="mce-EMAIL" type="email" value="" name="EMAIL" class="required email" required>
                  <span id="mce-EMAIL-HELPERTEXT" class="helper_text" />
                </div>
                <div class="mb-4">
                  <p class="small">通過提交您的資料，您同意接收來自 beNovelty 或 OpenAPIHub by beNovelty 的信息。</p>
                </div>
                <div hidden="true"><input type="hidden" name="tags" value="2142806,2518966"></div>
                <div id="mce-responses" class="clear">
                  <div id="mce-error-response" class="response" style="display:none" />
                  <div id="mce-success-response" class="response" style="display:none" />
                </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_b928e5178a4f8a241b088732f_dae542b6e6" tabindex="-1" value=""></div>
                <div class="clear"><input id="mc-embedded-subscribe" type="submit" value="提交" name="subscribe" class="btn btn-primary border-0 mx-auto"></div>
              </form>
            </div>
            <!--End mc_embed_signup-->

            <!--[if lte IE 8]>
                <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2-legacy.js"></script>
                <![endif]-->
            <!-- <form ref="subscribeForm" /> -->
            <!-- <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js" />
            <script>
              hbspt.forms.create({
              portalId: "7091000",
              formId: "4daac438-a701-4a30-89de-fefdd7a7306f"
              });
            </script> -->
          </div>
          <!-- End Subscribe Form -->
        </div>

        <div class="col-lg-3 d-none d-lg-block" data-aos="fade-left">
          <div class="max-w-33rem w-100 transform-rotate-2 ml-auto">
            <div class="device device-iphone-x">
              <img class="device-iphone-x-frame" src="@/assets/svg/illustrations/subscribe-mobile.svg">
            </div>

            <figure class="max-w-19rem w-100 position-absolute top-0 left-0 z-index-n1 mt-n5 ml-n5">
              <img class="img-fluid" src="@/assets/svg/components/dots-2.svg">
            </figure>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Subscription Section ========== -->

    <!-- ========== Popup Modals ========== -->
    <!-- Hubspot Form - OAH Modal -->
    <div id="oahForm" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="oahFormTitle" aria-hidden="true">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="oahFormTitle" class="modal-title" />
            <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
              <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </button>
          </div>
          <!-- Body -->
          <div class="modal-body">
            <div class="w-md-90 mx-auto mb-6 form-submit">
              <!--[if lte IE 8]>
                    <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2-legacy.js"></script>
                    <![endif]-->
              <form ref="oahForm" />
              <!-- <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js" />
              <script>
                hbspt.forms.create({
                portalId: "7091000",
                formId: "9000df0e-4c8c-4cbc-be0e-ca5c731b90cf"
                });
              </script> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Hubspot Form - OAHModal -->
    <!-- ========== End Popup Modals ========== -->
  </main>
</template>

<script>
const $ = require('jquery')
import HSGoTo from '@/assets/vendor/hs-go-to/src/js/hs-go-to'
import 'bootstrap'
import '@/assets/js/hs.core.js'
import '@/assets/js/hs.slick-carousel.js'
import '@/assets/vendor/slick-carousel/slick/slick.js'
import { mapGetters } from 'vuex'
export default {
  name: 'Index',
  components: {
  },
  data() {
    return {
      about_list: [
        '隨着疫情影響，中小企今年更着重數碼轉型，紛紛添置軟件硬件和雲端工具，以應對員工在家工作及客戶在家消費的趨勢。因此，中小企面對的網絡風險亦隨之增加。',
        'OneDegree所提供的網絡保險專為中小企設計，不但申請過程簡單，保障範圍亦十分全面。'
      ],
      coverage_list: [
        {
          title: 'Crisis Management',
          image_url: 'coverage-1.svg',
          content: [
            'Data Forensic Expenses',
            'Breach Consultation Services',
            'Costs to Restore',
            'Breach Response (Notification, Credit, and ID Monitoring)',
            'Public Relations'
          ]
        },
        {
          title: 'Business Interruption',
          image_url: 'coverage-2.svg',
          content: [
            'Business Interruption (8 hours waiting period)'
          ]
        },
        {
          title: 'Third Party Liability',
          image_url: 'coverage-3.svg',
          content: [
            'Third Party Liability',
            'Regulator Liability',
            'Investigation Liability',
            'Consumer Redress Fund',
            'PCD DSS Cover'
          ]
        },
        {
          title: 'First Party Coverage',
          image_url: 'coverage-4.svg',
          content: [
            'Hacker Theft Cover',
            'Network Extortion Coverage',
            'Loss Adjustor Cost'
          ]
        },
        {
          title: 'Automatic Extensions',
          image_url: 'coverage-5.svg',
          content: [
            'Personal Reputation Cover',
            'Emergency Cost',
            'Loss Mitigation Costs'
          ]
        },
        {
          title: 'Policy Conditions',
          image_url: 'coverage-6.svg',
          content: [
            'Continuity Date',
            'Retroactive Date',
            'Territory/Jurisdiction',
            'Period of Insurance',
            'Defence Costs'
          ]
        }
      ],
      feature_list: [
        {
          image_url: 'icon-31.svg',
          content: '年保障額高達100萬美元'
        },
        {
          image_url: 'icon-22.svg',
          content: '保障全面'
        },
        {
          image_url: 'icon-40.svg',
          content: '申請手續快捷方便'
        },
        {
          image_url: 'icon-5.svg',
          content: '年度保費HK$6830起'
        }
      ],
      fiberapi_list: [
        'FiberAPI致力以API技術開發各種工具，協助中小企簡化營運流程及管理財務，提高整體效率。',
        '在處理客戶的財務數據時，FiberAPI採取銀行級別的網絡安全措施。 網絡保險有助於管理網絡風險，並為企業提供額外的保護。'
      ],
      media_coverage_list: [
        {
          url: 'https://blog.openapihub.com/zh-hk/cyber-insurance-faq/',
          image_url: 'cyber-insurance-faq.jpg',
          category: '文章',
          title: '有關網絡安全保險的六大常見問題'
        },
        {
          url: 'https://blog.openapihub.com/zh-hk/benovelty-onedegree-cyber-insurance/',
          image_url: 'press-cover.jpg',
          category: '新聞發報',
          title: 'OneDegree夥拍beNovelty推出針對中小企網絡保險 應對數碼時代衍生網絡風險'
        },
        {
          url: 'https://www.visiongo.hsbc.com.hk/zh-HK/event/benovelty_onedegree_fiberapi',
          image_url: '20201112-banner.jpg',
          category: '網絡研討會',
          title: '如何應對數碼化帶來的網絡風險？'
        }
      ],
      selected_coverage: 'pills-code-features-0'
    }
  },
  computed: {
    ...mapGetters([
    ])
  },
  mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    this.loadScriptOahForm('https://js.hsforms.net/forms/v2.js', () => {
      const secondScript = document.createElement('script')
      secondScript.innerHTML = `
        hbspt.forms.create({
          portalId: "7091000",
          formId: "13239e83-63b2-4469-8e47-aaf91bdc82d2"
        });
      `
      this.$refs.oahForm.appendChild(secondScript)
    })
    // this.loadScriptSubscribeForm('https://js.hsforms.net/forms/v2.js', () => {
    //   const secondScript = document.createElement('script')
    //   secondScript.innerHTML = `
    //     hbspt.forms.create({
    //       portalId: "7091000",
    //       formId: "1733dccd-eeb8-42ae-a361-cc4a5b56428b"
    //     });
    //   `
    //   this.$refs.subscribeForm.appendChild(secondScript)
    // })
  },
  methods: {
    loadScriptOahForm(url, callback) {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = url
      script.onreadystatechange = callback
      script.onload = callback
      this.$refs.oahForm.appendChild(script)
    }
    // loadScriptSubscribeForm(url, callback) {
    //   var script = document.createElement('script')
    //   script.type = 'text/javascript'
    //   script.src = url
    //   script.onreadystatechange = callback
    //   script.onload = callback
    //   this.$refs.subscribeForm.appendChild(script)
    // }
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'beNovelty x OneDegree Cyber Insurance | Partnership Offer | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/resource-library/benovelty-onedegree-cyber-insurance-tc' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'beNovelty x OneDegree Cyber Insurance | Partnership Offer | beNovelty' },
        { property: 'og:description', content: 'Cyber Insurance for Digital-Transforming SMEs (D-Biz & TVP applicable) & Open Banking TSPs' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/resource-library/benovelty-onedegree-cyber-insurance-tc' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../assets/vendor/slick-carousel/slick/slick.css");
</style>
